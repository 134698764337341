import React from 'react';
import FadeIn from 'react-fade-in';

class Catalog extends React.Component {
  render() {
    return (
      <FadeIn>
        <p>include photos and videos.</p>
      </FadeIn>
    )
  }
}

export default Catalog;